<template>
    <div class="up_content_inner up_support_tickets">
        <div class="up_main_container">
            <div class="up_page_header p-mb-4">
                <h2 class="up_page_title">Support Ticket</h2>
            </div>
            <div class="up_inner_container">
                <iframe title="Feedback Form" class="freshwidget-embedded-form" id="freshwidget-embedded-form" src="https://truabilities.freshdesk.com/widgets/feedback_widget/new?&amp;widgetType=embedded&amp;submitTitle=Share+Feedback&amp;submitThanks=Thanks+for+contacting+Truabilities.+One+of+our+associates+will+get+back+to+you+shortly.+&amp;screenshot=No&amp;attachFile=no&amp;searchArea=no" scrolling="no" height="500px" width="100%" frameborder="0"></iframe>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SupportTickets',
    setup(){
        
    }
}
</script>

<style>

</style>